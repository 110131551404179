<template>
    <div class="comic-index">
        <!-- 首页header（发现 & 推荐 & 搜索） -->
        <div class="header">
            <div class="tabs" :class="{ 'show-tabs': showTabs }">
                <span
                    class="tab-item"
                    :class="{ 'active-tab': item.index === activeTabIndex }"
                    v-for="item in tabs"
                    :key="item.key"
                    @click.passive="changeTabHandle(item.index)"
                >
                    {{ item.name }}
                </span>
            </div>
            <div class="login-btn" v-if="!userInfo.isLogin" @click="goLogin">登录</div>
            <SearchInput
                v-else
                class="search-input"
                readonly
                placeholder="请输入漫画名"
                @click="toSearch"
            />
        </div>
        <Swiper
            ref="swiperWrap"
            class="swiper"
            @change="tabSlideHandle"
            :defaultIndex="genderTabDefaultIndex"
            :move-condition="moveCondition"
        >
            <SwiperItem v-for="c in tabs" :key="c.key" class="swiper-item" :index="c.index">
                <FindTab
                    ref="findTab"
                    :gender="c.index"
                    :resourceInfo="resourceInfo"
                    @switchCanSwiper="changeMoveCondition"
                    @hideLoading="loadingHide"
                />
            </SwiperItem>
        </Swiper>
        <button class="refresh" @click="doRefresh()"></button>
    </div>
</template>
<script>
import { Swiper, SwiperItem } from '@kk/swiper';
import FindTab from '@/views/Index/Tabs/Find/index.vue';
import { mapGetters, mapState } from 'vuex';
import SearchInput from '@/components/SearchInput/index.vue';
import resourceController from '@/components/Resource/Controller.js';

export default {
    name: 'Index',
    data: function () {
        return {
            tabs: [
                { name: '男生', key: 'boy', index: 0 },
                { name: '女生', key: 'girl', index: 1 },
            ],
            activeTabIndex: null,
            canMove: true,
            from: '',
            resourceInfo: {},
        };
    },
    components: {
        Swiper,
        SwiperItem,
        FindTab,
        SearchInput,
    },
    computed: {
        ...mapState(['userInfo']),
        ...mapGetters(['genderTabDefaultIndex']),
        showTabs() {
            return this.tabs.length > 1;
        },
    },
    methods: {
        goLogin() {
            this.$router.push({
                path: '/login',
            });
        },
        changeMoveCondition(lable) {
            this.canMove = lable;
        },
        moveCondition() {
            return this.canMove;
        },
        tabSlideHandle(gender) {
            this.activeTabIndex = gender;
            this.$store.commit('SET_GENDER', gender);
            this.sa('CommonPageOpen', {
                uid: this.$store.state.userInfo.uid,
                CurPage: this.activeTabIndex ? '女生页' : '男生页',
                PrePage: this.from,
            });
            this.$refs['findTab'][gender].getInitData();
        },
        changeTabHandle(gender) {
            this.$refs['swiperWrap'].slideTo(gender);
        },
        loadingHide() {
            loading.hide();
        },
        async doRefresh() {
            // 点击更新当前tab内数据
            loading.show();
            await this.$refs['findTab'][this.activeTabIndex].refreshFn();
            loading.hide();
        },
        setTrackFrom(router = {}) {
            const { trackName = '' } = router.meta || {};
            this.from = trackName === '首页' ? '' : trackName;
        },
        toSearch() {
            this.$router.push({
                path: '/search',
            });
        },
    },
    mounted() {
        this.$nextTick(() => {
            loading.show();
            this.tabSlideHandle(this.genderTabDefaultIndex);
        });
    },
    async activated() {
        document.title = '首页';
        this.resourceInfo = await resourceController.fetchResourceInfo(
            resourceController.RESOURCE_PAGE_NAME.COMIC_INDEX
        );
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.setTrackFrom(from);
        });
    },
};
</script>
<style scoped lang="less">
@import '~@/assets/vws.less';
.comic-index {
    overflow-x: hidden;
    height: 100vh;
    background: #111224;
}
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 64px;
    box-sizing: border-box;
    .vws(padding-left, 24px);
    .vws(padding-right, 24px);
}
.login-btn {
    display: flex;
    font-size: 17px;
    font-weight: 500;
    color: #a7a9b6;
    flex: none;
    line-height: 21px;
}
.login-btn::before {
    display: inline-block;
    margin-right: 3px;
    width: 1.2em;
    height: 1.2em;
    background: url('./login.png') no-repeat;
    background-size: 100% 100%;
    content: '';
}
.search-input {
    flex: 1;
    background: #1d1f31;
}
.tab-item {
    position: relative;
    margin-right: 24px;
    padding: 5px 0;
    font-size: 17px;
    color: #a7a9b6;
}
.tab-item.active-tab {
    font-weight: 700;
    color: #fff;
}
.show-tabs .tab-item.active-tab::after {
    position: absolute;
    bottom: 0;
    left: calc(50% - 8.5px);
    width: 17px;
    height: 3px;
    border-radius: 2px;
    background: #f44;
    content: '';
    animation: tabShow 0.2s forwards ease-out;
}
@keyframes tabShow {
    from {
        transform: scaleX(0.3);
    }
    to {
        transform: scaleX(1);
    }
}
.swiper {
    height: calc(100% - 64px);
}
.swiper /deep/ .loading {
    background: #111224;
    .loading-content {
        color: #fff;
    }
}
.refresh {
    position: fixed;
    right: 0;
    bottom: 12vh;
    width: 70px;
    height: 70px;
    background: url('./refresh.png') no-repeat center/100%;
}
.temp {
    width: 100vw;
    height: 100vh;
    background: blue;
}
.swiper-item {
    overflow-y: scroll;
    height: 100%;
}
.swiper-item::-webkit-scrollbar {
    display: none;
}
</style>
