<template>
    <div class="resource-banner">
        <swiper
            ref="swiper"
            class="swiper-wrap"
            :defaultIndex="defaultIndex"
            :swiperItemNum="bannerList.length"
            @change="onChangeSwiperAndReport"
            :moveCondition="moveCondition"
        >
            <swiper-item
                :useloading="false"
                v-for="(item, index) in showBannerList"
                :key="`${index}-${item.id}`"
            >
                <div @touchstart="onTouchStart" @touchend="onTouchEnd">
                    <bannerItem :bannerInfo="item" :index="index" @jump="jumpHandler" />
                </div>
            </swiper-item>
        </swiper>
        <div class="swiper-button-list" v-if="bannerList.length > 1">
            <div
                v-for="(banner, index) in bannerList"
                :key="banner.id"
                class="swiper-button"
                :class="{ active: index + 1 === activeIndex }"
            ></div>
        </div>
    </div>
</template>

<script>
import bannerItem from './item.vue';
import resourceController from '@/components/Resource/Controller.js';
import swiperMixin from '@/mixin/swiperMixin.js';

export default {
    props: {
        bannerList: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
    mixins: [swiperMixin],
    components: {
        bannerItem,
    },
    data() {
        return {};
    },
    computed: {
        // showBannerList非真实的banner数目，真实数目使用bannerList
        // 将最后一张图放到第一张前面，第一张图放到最后一张后面，保证轮播图的无缝滚动，
        showBannerList() {
            const list = this.bannerList || [];
            return list.length <= 1 ? list : [list[list.length - 1], ...list, list[0]];
        },
        defaultIndex() {
            return this.showBannerList.length > 1 ? this.showBannerList.length - 1 : 0;
        },
    },
    mounted() {
        this.slideSwiper();
    },
    activated() {
        this.slideSwiper();
    },
    methods: {
        onChangeSwiperAndReport(index) {
            this.onChangeSwiper(index);
        },
        jumpHandler(index) {
            resourceController.jumpTo(this.showBannerList[index]);
        },
        moveCondition() {
            return this.bannerList.length > 1;
        },
        // banner滚动时 屏蔽外层swiper滚动能力
        onTouchStart() {
            this.$emit('switchCanSwiper', false);
        },
        // banner滚动结束 恢复外层swiper滚动能力
        onTouchEnd() {
            this.$emit('switchCanSwiper', true);
        },
    },
};
</script>

<style lang="less" scoped>
@import '~@/assets/vws.less';
.resource-banner {
    position: relative;
}
.swiper-button-list {
    position: absolute;
    left: 50%;
    z-index: 1;
    display: flex;
    transform: translateX(-50%);
    .vws(bottom, 32px);
}
.swiper-button {
    margin-right: 4px;
    width: 2vw;
    min-width: 16px;
    height: 2px;
    border-radius: 2px;
    background: rgba(255, 255, 255, 0.5);
}
.swiper-button.active {
    background: rgb(255, 255, 255);
}
</style>
