import { Swiper, SwiperItem } from '@kk/swiper';

export default {
    data() {
        return {
            activeIndex: 1,
            swiperTimer: null,
        };
    },
    components: {
        Swiper,
        SwiperItem,
    },
    methods: {
        slideSwiper() {
            clearTimeout(this.swiperTimer);
            this.swiperTimer = setTimeout(() => {
                let index = this.activeIndex;
                if (!this.$refs['swiper']?.$attrs) return;
                const length = this.$refs['swiper'].$attrs.swiperItemNum || 0 + 1;
                if (index === 0) {
                    this.slideTo(length);
                } else if (index === length + 1) {
                    this.slideTo(0);
                } else {
                    this.slideTo(index + 1);
                }
            }, 3000);
        },
        slideTo(index) {
            if (!this.$refs['swiper']?.$children[index]) return;
            this.$refs['swiper'] && this.$refs['swiper'].slideTo(index);
        },
        onChangeSwiper(index) {
            this.activeIndex = index;
            if (!this.$refs['swiper']?.$attrs) return;
            const length = this.$refs['swiper'].$attrs.swiperItemNum + 1;
            let i = index !== undefined ? index : this.activeIndex;
            if (i == 0) {
                i = length - 1;
                this.slideTo(i);
            }
            if (i >= length) {
                i = 1;
                this.slideTo(i);
            }
            this.slideSwiper();
        },
        onTouchStart() {
            this.$emit('touchstart');
        },
        onTouchEnd() {
            this.$emit('onTouchEnd');
        },
    },
    deactivated() {
        clearTimeout(this.swiperTimer);
    },
    beforeDestroy() {
        clearTimeout(this.swiperTimer);
    },
};
