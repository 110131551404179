import Vue from 'vue';
import store from '@/store';
import { get } from '@/modules/request';
import router from '@/router/index.js';

// https://cn.vuejs.org/v2/api/#Vue-observable
// 最小化的跨组件状态存储器
// Vue.obserbale返回值可直接用于渲染函数和计算属性
const resourceDate = Vue.observable({
    resourceMap: {},
});

const RESOURCE_PAGE_NAME = {
    COMIC_INDEX: 1,
};

const ROUTER_PATH_NAME = {
    1: '/index',
    2: '/recent_read',
    3: '/user_info',
    9: '/comic_read',
};

const RESOURCE_LINK_TYPE = {
    COMIC: 1,
    RANK: 7, // 榜单
    PAGE: 8, // 页面
};

// 校验输入pageType是否合法
const checkPageTypeValid = (pageType) => {
    return Object.values(RESOURCE_PAGE_NAME).find((item) => item === pageType);
};

export const RESOURCE_TYPE_MAP = {
    // banner
    COMIC_INDEX_MALE_BANNER: 12,
    COMIC_INDEX_FEMALE_BANNER: 13,
};

export const getTypeList = (type) => {
    const filterKeys = Object.keys(RESOURCE_TYPE_MAP).filter((key) => {
        const keyArr = key.split('_');
        return keyArr[keyArr.length - 1] === type;
    });
    return filterKeys.map((key) => {
        return RESOURCE_TYPE_MAP[key];
    });
};
/**
 * @description 根据资源位获取前端资源位type 分销公众号只支持 弹窗 - 挂件
 * @param {Object} resource
 * @returns {String}
 */
const getResourceType = (resource) => {
    const { resourceType } = resource;
    const typeList = ['POPUP', 'OVERLAYER', 'BANNER', 'MENU'];
    for (const item of typeList) {
        const typeList = getTypeList(item);
        if (typeList.indexOf(resourceType) > -1) {
            return item;
        }
    }
    return '';
};

/**
 * @description 不更新数据，仅根据GlobalData获取页面信息，暴露给各个页面使用
 * @param {Number} pageType
 * @param {String} resourceType
 * @returns {Array}
 */
const getResourceInfo = (pageType) => {
    return resourceDate.resourceMap[pageType] || {};
};

/**
 * @description 将 resourceList根据pageTypes 扁平化
 * @param {Array} resourceList
 * @returns
 * @example
 * input: [{pageTypes: [1,2], id: 1}]
 * output: [{pageType: 1, id: 1}, {pageType: 2, id: 1}]
 */

const flatListByPageTypes = (resourceList = []) => {
    return resourceList.reduce((result, resource) => {
        const subList = resource.pageTypes.map((pageType) => {
            return {
                ...resource,
                pageType: pageType,
            };
        });
        return result.concat(subList);
    }, []);
};

/**
 * @description 根据 resourceType pageType 格式化数据
 * @param {Array} resourceList
 * @param {Number} pageType
 * @returns {Map}
 * @example
 * input:[{pageTypes: [1,2], type: POPUP, id: 1}, {pageTypes: [2,3], type: OVERLAYER, id: 2}]
 * ->
 * mid-status：
 * [{pageType: 1, type: POPUP, id: 1}, {pageType: 2, type: POPUP, id: 1}, {pageType: 2, type: OVERLAYER, id: 2}, {pageType: 3, type: OVERLAYER, id: 2}]
 * —>
 * output:
 * {
 *   1: {POPUP: {id: 1}},
 *   2: {POPUP: {id: 1},OVERLAYER: {id: 2}},
 *   3: {OVERLAYER: {id: 2}}
 * }
 */
const getResourceMap = (resourceList, pageType) => {
    if (pageType && !checkPageTypeValid(pageType)) {
        console.error('异常pageType参数');
        return {};
    }
    let base = {};
    if (pageType) {
        base = resourceDate.resourceMap;
        base[pageType] = {};
    }
    const flatList = flatListByPageTypes(resourceList);
    return flatList.reduce((result, resourceItem) => {
        const pageType = resourceItem.pageType;
        // 暂不支持的页面类型
        if (!checkPageTypeValid(pageType)) {
            return result;
        }
        // 初始化页面
        if (!result[pageType]) {
            result[pageType] = {};
        }
        const resourceType = getResourceType(resourceItem);
        if (resourceType === 'BANNER') {
            if (!result[pageType][resourceType]) {
                result[pageType][resourceType] = [];
            }
            result[pageType][resourceType].push(resourceItem);
        } else {
            result[pageType][resourceType] = resourceItem;
        }
        return result;
    }, base);
};
/**
 * @description 获取资源位信息
 * @param {Number} pageType
 */
const fetchResourceInfo = (pageType) => {
    const { channelId } = store.state.userInfo;
    let param = {
        channelId,
    };
    if (pageType) {
        param.pageType = pageType;
    }
    let queryList = [];
    function getResource() {
        return get({
            url:
                process.env.VUE_APP_DISTRIBUTION_HOST + '/distribution/resource/location/resources',
            data: param,
        });
    }
    queryList.push(getResource());
    return Promise.all(queryList)
        .then((res) => {
            const { code, data } = queryList.length > 1 ? res[1] : res[0];
            // 获取当前运营位信息失败 -> 不做任何更新
            if (code !== 200) {
                return;
            }
            // 更新 & 获取单个页面资源位
            resourceDate.resourceMap = Object.assign(
                {},
                resourceDate.resourceMap,
                getResourceMap(data, pageType)
            );
            if (pageType) {
                return getResourceInfo(pageType);
            }
        })
        .catch((err) => {
            console.error(err);
            return {};
        });
};

/**
 * @description 获取埋点中枚举字段值
 */
const getSaContent = (resource) => {
    const { resourceLinkType, resourceType, pageType } = resource;
    const resourceTypeStrMap = {
        [RESOURCE_TYPE_MAP.COMIC_INDEX_MALE_BANNER]: 'banner-男生书城页',
        [RESOURCE_TYPE_MAP.COMIC_INDEX_FEMALE_BANNER]: 'banner-女生书城页',
    };
    const resourceLinkTypeStrMap = {
        [RESOURCE_LINK_TYPE.COMIC]: '漫画',
        [RESOURCE_LINK_TYPE.CONTINUE_READ]: '继续阅读',
        [RESOURCE_LINK_TYPE.PAGE]: '页面', // 页面
    };

    const pageRoute = router.options.routes.find((item) => {
        return item.path === ROUTER_PATH_NAME[pageType];
    });

    return {
        resourceTypeStr: resourceTypeStrMap[resourceType],
        resourceLinkTypeStr: resourceLinkTypeStrMap[resourceLinkType],
        pageTypeStr: pageRoute.meta?.trackName,
    };
};

function getResourcePageRoute(linkNameId) {
    const pageRoute = router.options.routes.find((item) => {
        return item.meta?.serverId === linkNameId;
    });
    if (!pageRoute) {
        throw `没有支持${linkNameId}类型的页面跳转`;
    }
    return pageRoute;
}

function getLinkInfo(resource) {
    const { resourceLinkType, topicInfo, resourceLink = {} } = resource;
    const { linkNameId } = resourceLink;
    const { resourceTypeStr } = getSaContent(resource);
    let url,
        name = '',
        query = {},
        params = {},
        contentName = '',
        contentID = 0;
    const setComicInfo = (isContinue) => {
        name = 'ComicRead';
        (query = isContinue ? { isRecentRead: true } : { topicId: topicInfo.topicId }),
            (params = {
                SourceModule: resourceTypeStr,
            });
    };
    if (resourceLinkType === RESOURCE_LINK_TYPE.PAGE) {
        contentID = linkNameId;
        if (linkNameId === 'ydjl') {
            // 最近阅读漫画
            setComicInfo(true);
            contentName = '继续阅读';
        } else {
            const pageRoute = getResourcePageRoute(linkNameId);
            name = pageRoute?.name || '';
            contentName = pageRoute?.meta?.trackName || '';
        }
    } else if (resourceLinkType === RESOURCE_LINK_TYPE.COMIC) {
        setComicInfo(false);
        contentID = topicInfo.topicId;
        contentName = topicInfo.topicAlias;
    }
    return {
        url,
        name,
        query,
        params,
        contentName,
        contentID,
    };
}

/**
 * @description 资源位跳转通用方法
 * @param {Object} resource
 *
 */
const jumpTo = (resource) => {
    const { id, resourceType } = resource;
    const { url, name, query, params } = getLinkInfo(resource);
    const payInfo = {
        resourceId: id,
        resourceType: resourceType,
    };
    if (['ChargeActivity', 'ComicRead', 'Charge'].includes(name)) {
        params.payInfo = payInfo;
    }
    if (url) {
        location.href = url;
        return;
    }
    router.push({
        name,
        query,
        params,
    });
};

export default {
    resourceDate,
    fetchResourceInfo,
    getResourceInfo,
    jumpTo,
    RESOURCE_PAGE_NAME,
    RESOURCE_LINK_TYPE,
};
