<template>
    <div class="search-input-wrapper" @click="onClick">
        <div class="icon search-icon">
            <img src="./assets/search-icon.png" />
        </div>
        <input
            type="text"
            class="input"
            data-cy="search-input"
            :placeholder="placeholder"
            :readonly="readonly"
            v-model="searchValue"
            @keydown="onkeydown"
            ref="input"
        />
        <div class="icon clear-icon" v-if="clearable && searchValue" @click.stop="clearValue">
            <img src="./assets/clear-icon.png" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'SearchInput',
    props: {
        value: {
            type: String,
            default: '',
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: '请输入...',
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            searchValue: '',
        };
    },
    watch: {
        value(val) {
            this.searchValue = val;
        },
        searchValue(value) {
            this.$emit('input', value);
            this.$emit('change', value);
        },
    },
    methods: {
        onkeydown(event) {
            if (event.keyCode === 13) {
                this.$emit('search', this.searchValue);
            }
        },
        focus() {
            this.$refs.input.focus();
        },
        clearValue() {
            this.searchValue = '';
            this.focus();
            this.$emit('clear');
        },
        onClick() {
            this.$emit('click');

            if (!this.readonly) {
                this.focus();
            }
        },
    },
};
</script>

<style lang="less" scoped>
@import '~@/assets/vws.less';

.search-input-wrapper {
    display: flex;
    align-items: center;
    height: 2.67em;
    font-size: 12px;
    border-radius: 1.33em;
    background: #f7f7f8;
    box-sizing: border-box;
    .vws(padding-left, 20px);
    .vws(padding-right, 16px);
}

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.33em;
    height: 1.33em;

    /* stylelint-disable-next-line scss/selector-no-redundant-nesting-selector */
    & > img {
        width: 100%;
        height: 100%;
    }
}

.search-icon {
    .vws(margin-right, 8px);

    flex: none;
}

.clear-icon {
    .vws(margin-left, 8px);

    flex: none;
}

.input {
    padding: 0;
    width: 100%;
    border: none;
    color: #333;
    background-color: transparent;
    outline: none;
    box-sizing: border-box;
    .vws(height, 64px);
}

.input::placeholder {
    color: rgba(184, 184, 184, 0.5);
}
</style>
