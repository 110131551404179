<template>
    <div class="banner-item" :class="wrapClass" @click="resourceJump">
        <template v-if="isComicItem">
            <img class="cover" :src="topicInfo.verticalCoverUrl | compress(702)" alt="" />
            <div class="bottom-mask">
                <div class="title">
                    {{ topicInfo.topicAlias }}
                </div>
                <div class="intro-text">
                    {{ topicInfo.topicDescription }}
                </div>
            </div>
        </template>
        <template v-else>
            <img class="banner-image" :src="bannerInfo.pageImage | compress(702)" />
        </template>
    </div>
</template>
<script>
export default {
    props: {
        bannerInfo: {
            type: Object,
            default: () => {
                return {};
            },
        },
        index: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        topicInfo() {
            return this.bannerInfo?.topicInfo || {};
        },
        isComicItem() {
            return this.bannerInfo.resourceLinkType === 1;
        },
        wrapClass() {
            if (this.isComicItem) {
                return 'comic-item';
            }
            return 'normal-item';
        },
    },
    methods: {
        resourceJump() {
            this.$emit('jump', this.index);
        },
    },
};
</script>
<style lang="less" scoped>
@import '~@/assets/vws.less';
.banner-item {
    position: relative;
    padding-top: 12px;
    .vws(padding-left, 24px);
    .vws(padding-right, 24px);
    .vws(height, 652px);
    .vws(width, 702px);
    .banner-image {
        width: 100%;
        height: .convert(250px) [ @vw];
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
    }
    .cover {
        width: 100%;
        height: 100%;
        border-radius: 6px;
        object-fit: cover;
    }
    .bottom-mask {
        position: absolute;
        bottom: 0;
        .vws(left, 24px);
        .vws(width, 702px);
        .vws(height, 262px);
        .vws(padding-left, 24px);

        box-sizing: border-box;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
        .title {
            .vws(width, 400px);

            font-size: 24px;
            line-height: 28px;
            font-weight: 600;
            color: #fff;
            .ellipsis-ln(2);
        }
        .tag-list {
            font-size: 0;
            .vws(margin-top, 6px);
            .vws(margin-bottom, 6px);
            .tag {
                display: inline-block;
                margin-right: 8px;
                padding: 3px 10px;
                font-size: 10px;
                border-radius: 100px;
                color: #fff;
                background: #5874fb;
            }
        }
        .intro-text {
            font-size: 12px;
            text-overflow: ellipsis;
            color: #fff;
            line-height: 19px;
            .vws(margin-top, 48px);
            .vws(width, 654px);
            .ellipsis(1);
        }
    }
}
</style>
