<template>
    <div class="find-tab-wrap" :class="`find-tab-wrap-${gender}`">
        <resourceBanner
            :bannerList="resourceBannerList"
            @switchCanSwiper="
                (val) => {
                    this.$emit('switchCanSwiper', val);
                }
            "
        />
        <component
            v-for="moduleItem in displayModules"
            :key="moduleItem.moduleId"
            :is="modulesMap[moduleItem.cardType]"
            :moduleInfo="moduleItem"
        />
        <Registration v-if="displayModules.length" />
    </div>
</template>
<script>
import { get } from '@/modules/request.js';
import { throttle } from '@kk/utils';
import { mapGetters } from 'vuex';
import Registration from './Registration.vue';
import { RESOURCE_TYPE_MAP } from '@/components/Resource/Controller.js';
import resourceBanner from '@/components/Resource/Banner/index.vue';

export default {
    data: function () {
        return {
            page: 0,
            hasMore: false,
            indexModules: [],
            modulesMap: {
                // ENTRANCE_BANNER => 首页横向按钮
                ENTRANCE_BANNER: () =>
                    import(
                        /* webpackChunkName: "indexModules" */ '@/views/Index/Tabs/Find/Modules/VerticalBtns/index.vue'
                    ),
                // VERTICAL_TOPIC_6 => 六图模块
                VERTICAL_TOPIC_6: () =>
                    import(
                        /* webpackChunkName: "indexModules" */ '@/views/Index/Tabs/Find/Modules/SixPic/index.vue'
                    ),
            },
            scrollDom: null,
            onRequest: false,
            scrollTop: 0,
        };
    },
    props: {
        // eslint-disable-next-line vue/require-default-prop
        gender: {
            type: Number,
        },
        resourceInfo: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    components: {
        Registration,
        resourceBanner,
    },
    computed: {
        ...mapGetters(['channelId']),
        displayModules() {
            return this.indexModules.filter((item) => this.modulesMap[item.cardType]);
        },
        resourceBannerList() {
            return (this.resourceInfo?.BANNER || []).filter((item) => {
                const resourceType =
                    this.gender === 1
                        ? RESOURCE_TYPE_MAP.COMIC_INDEX_FEMALE_BANNER
                        : RESOURCE_TYPE_MAP.COMIC_INDEX_MALE_BANNER;
                return item.resourceType === resourceType;
            });
        },
    },
    methods: {
        async getInitData() {
            await this.getIndexConfig(true);
            this.$emit('hideLoading');
        },
        getIndexConfig(isInit = false) {
            if (isInit && this.page !== 0) return; // 非首次tab切换不请求数据
            this.onRequest = true;
            return get({
                url: process.env.VUE_APP_DISTRIBUTION_HOST + '/distribution/homepage',
                data: {
                    page: this.page,
                    gender: this.gender + 1,
                    channelId: this.channelId,
                },
            })
                .then((res) => {
                    this.onRequest = false;
                    const { hasMore, list } = res.data;
                    this.indexModules = this.indexModules.concat(list);
                    this.hasMore = hasMore;
                    this.page++;
                    return res.data;
                })
                .catch((err) => {
                    this.onRequest = false;
                    console.error({ err });
                });
        },
        refreshFn() {
            this.removeScroll();
            this.indexModules = [];
            this.page = 0;
            return this.getIndexConfig().then(() => {
                this.bindScroll();
            });
        },
        scrollHandler() {
            return throttle(() => {
                const { clientHeight, scrollTop, scrollHeight } = this.scrollDom;
                const leftDis = scrollHeight - clientHeight - scrollTop;
                this.scrollTop = scrollTop;
                if (this.hasMore && !this.onRequest && leftDis < 400) {
                    this.getIndexConfig();
                }
            }, 200);
        },
        removeScroll() {
            this.scrollDom.removeEventListener('scroll', this.scrollHandler());
        },
        bindScroll() {
            this.scrollDom.addEventListener('scroll', this.scrollHandler());
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.scrollDom = document.querySelector(`.find-tab-wrap-${this.gender}`).parentNode;
            this.bindScroll();
        });
    },
    activated() {
        this.$nextTick(() => {
            this.scrollDom.scrollTop = this.scrollTop;
        });
    },
};
</script>
<style scoped>
.find-tab-wrap {
    box-sizing: border-box;
    overflow-x: hidden;
}
.beian {
    font-size: 12px;
    text-align: center;
    color: #666;
    background: #ccc;
    line-height: 20px;
}
</style>
